.rounded-image {
    border-radius: 50%;
    align-self: center;
    width: 10rem;
}

.logo-image {
    width: 15rem;
    align-self: center;
}

.maintence-logo {
    width: 5rem;
    align-self: center;
}