.white-header-menu {
    ul {
        display: flex;
        padding: 0;
        margin-bottom: 0;

        li {
            list-style: none;
            padding: 0 8px;

            a {
                font-family: Montserrat md;
                color: #f2f2f2;
                border: 1px black;
                text-decoration: none;

                &:hover {
                    color: #8e8e8e;
                    transition: ease-in-out 0.5s;
                }
            }
        }
    }
}