.container-element {
    min-height: calc(100vh - 25px - 40px - 30px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}