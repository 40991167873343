#form-contact{
    width: 80%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #F2F2F2;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;

    .form-group{
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        label{
            font-family: Bebas Neue;
            font-size: 20px;
            color: #535353;
            margin-bottom: 5px;
        }

        input{
            width: 95%;
            border: 1px solid #ccc;
            border-radius: 2px;
            padding: 10px;
            font-family: "JetBrains Mono",monospace;
            font-size: 15px;
            color: #535353;
            margin-bottom: 10px;
        }

        textarea{
            width: 95%;
            border: 1px solid #ccc;
            border-radius: 2px;
            padding: 10px;
            font-family: "JetBrains Mono",monospace;
            font-size: 15px;
            color: #535353;
            margin-bottom: 10px;
        }
    }

    #form-message {
        font-family: 'Montserrat md';
        font-size: 11px;
        align-self: center;
        text-align: center;
        padding: 5px;
        color: #fff;
        border-radius: 3px;
        margin-bottom: 5px;
    }

    #form-message.hidden{
        display: none;
    }

    #form-message.success {
        background-color: #5feb5f;
        color: #535353;
    }

    #form-message.error {
        background-color: #eb5f5f;
    }

    button {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 2px;
        padding: 10px;
        font-family: "JetBrains Mono",monospace;
        font-size: 15px;
        color: #535353;
        margin-bottom: 10px;

        &:hover{
            background-color: #535353;
            color: #F2F2F2;
            transition: ease-in-out 0.2s;
        }
    }
}