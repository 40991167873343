.header-element {
    align-items: center;
    background: #F2F2F2;
    display: flex;
    justify-content: center;
    padding: 0 2rem;
    min-height: 100px;

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px;
        justify-content: space-around;
        gap: 20vw;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 0;
        }

        .header-logo {
            height: 80px;
        }
    }
}