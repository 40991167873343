.transparent-header-element {
    align-items: center;
    background: transparent;
    display: flex;
    justify-content: center;
    min-height: 100px;
    position: absolute;
    top: 0;
    align-self: center;

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px;
        justify-content: space-around;
        gap: 20vw;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 0;
        }

        .transparent-header-logo {
            height: 80px;

            path {
                fill: #f2f2f2 !important;
            }
        }
    }
}