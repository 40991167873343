.white-subtitle {
    font-family: Montserrat rg;
    font-size: 2.6rem;
    color: #f2f2f2;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 2.4rem;
    }

    @media (max-width: 480px) {
        font-size: 2rem;
    }

    @media (max-width: 320px) {
        font-size: 1.6rem;
    }
}